import React, { useState } from "react";
import Lottie from "react-lottie";
import casehero from "../json/Case Study.json";
import "../blogs/blogs.css";
import investorpitch from "../../assets/blogs/investorimage.webp";

const InvestorPitch = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: casehero,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // Setting 'tab1' as the default open question
  const [activeTab, setActiveTab] = useState("tab1");
  const toggleTab = (tab) => {
    setActiveTab(activeTab === tab ? null : tab); // Toggle the active tab
  };
  return (
    <>
      <div class="blog-details">
        <div className="container-fluid case-sec">
          <div className="row">
            <div className="col-md-12">
              <a href="/blogs" class="back-button">
                <svg
                  id="Layer_1"
                  className="back-arrow"
                  width="35px"
                  enable-background="new 0 0 24 24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m23.939 12-3.454-1.995v1.653l-20.424.342 20.424.342v1.653z" />
                </svg>
                Back to Blogs
              </a>
            </div>
            <div className="col-md-12">
              <div className="blogs-hero-text">
                <p>
                  How to make an investor pitch deck : a step-by-step approach.
                </p>
                {/* 
               <h1>
                  Dive into insightful articles, expert tips, and the latest trends 
                  in the digital world to help your business thrive.  
               </h1>
               */}
              </div>
            </div>
          </div>
          <div className="row justify-content-center mobile-reverse">
            <div className="col-lg-8">
              <div className="blogdetail-img">
                {/* <p>
                  Want to start a business? Or maybe you’re looking to scale up?{" "}
                  <br /> If YES, this blog is for you!
                </p> */}
                <img className="w-100 py-4" src={investorpitch} />
              </div>
              <div className="blogdetails-content">
                <section id="section0">
                  <p>
                    <strong>
                      Do you know what makes an investor say, “I’m in!”?{" "}
                    </strong>{" "}
                    It’s not just the numbers or a great product idea—it’s a
                    story that hooks them from the start, a plan that shows them
                    the future, and a presentation that leaves them with no
                    doubt about your potential.
                  </p>
                  <p>
                    If you’ve been confused about how to make an investor pitch
                    deck that makes the investors say wow, you’re in the right
                    place. In this blog, we’ll walk you through the essentials
                    of a killer investor pitch.
                  </p>
                </section>
                <section id="section1">
                  <h2>What is an Investor Pitch?</h2>
                  <p>
                    An investor pitch is a brief and impactful presentation that
                    introduces your business and product idea to potential
                    investors. The main goal is to convince them to invest by
                    highlighting your business's potential, the team’s expertise
                    and a clear path to profitability. It’s more than just a
                    presentation; it’s your chance to tell a compelling story
                    that connects with investors and makes them believe that
                    your business is worth their investment.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <b>1. Start with a Bang:</b>
                      </p>
                      <p>
                        ‘Your first impression has to be your best impression.’
                      </p>
                      <p>
                        <strong>
                          ‘75% of investors make up their mind about an investor
                          pitch in the first two minutes’.
                        </strong>{" "}
                        Start with a compelling story or an eye-opening fact. In
                        the first few minutes, you must capture the investors’
                        attention and make them curious about what’s coming
                        next.
                      </p>
                      <p>
                        Introduce yourself and your team. Give a brief but
                        impactful introduction. Mention your name, your company,
                        and what makes you and your team the best people to
                        solve this problem. Investors invest in people as much
                        as they do in ideas, so make sure you highlight your
                        team’s expertise and experience.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>2. The Problem Statement</b>
                      </p>
                      <p>
                        Investors need to know that you understand the problem
                        you’re trying to solve. Be clear and concise. What’s the
                        problem, and who has it? Use real-world examples to make
                        it relatable. For example: ‘Countless small businesses
                        face challenges finding budget-friendly digital
                        marketing options. They end up spending thousands on
                        ineffective campaigns.’ Highlighting these pain points
                        helps investors see the gap in the market that your
                        product/service addresses.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>3.Solution and Unique Value Proposition (UVP)</b>
                      </p>
                      <p>
                        Now that you’ve set the stage, it’s time to introduce
                        your solution. This is where you discuss your product or
                        service. What is it? How does it solve the problem? And
                        most importantly, why is it better than anything else in
                        the market?
                      </p>
                      <p>
                        <strong>
                          {" "}
                          Highlight your Unique Value Proposition{" "}
                        </strong>{" "}
                        (UVP): Your solution needs to stand out. Explain what
                        makes your product or service different and why people
                        should care. Investors are interested in what sets you
                        apart from the competition and why customers will choose
                        you over others
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>4. Market Opportunity</b>
                      </p>
                      <p>
                        To show the market opportunity for your solution, it's
                        important to break it down into three key areas:
                      </p>
                      <ul style={{ listStyle: "decimal", marginLeft: "2rem" }}>
                        <li>
                          <strong>Total Addressable Market (TAM):</strong> This
                          is the overall market demand for your product or
                          service, assuming there are no limits on your reach.
                          It represents the biggest opportunity if you could
                          sell to everyone in your target market.
                        </li>
                        <li>
                          <strong>Serviceable Available Market (SAM):</strong>{" "}
                          This is the portion of the TAM that your product or
                          service can realistically reach, based on your
                          business model and resources. It's the segment you can
                          target given your current capabilities.
                        </li>
                        <li>
                          <strong>Serviceable Obtainable Market (SOM):</strong>{" "}
                          This is the share of the SAM that you can
                          realistically capture. It represents your target
                          customers who are most likely to buy from you in the
                          near future.
                        </li>
                      </ul>
                      <p>
                        By focusing on these areas, you can highlight market
                        trends, and growth potential, and explain why this is
                        the perfect time for your solution.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>5. The Business Model</b>
                      </p>
                      <p>
                        Investors want to know how your business will make
                        profits. This is your chance to show that your business
                        is sustainable and has the potential for growth. Be
                        clear and specific in your explanation of your revenue
                        streams, pricing strategy, and the scalability of your
                        business. Investors need to see how your business will
                        generate profits.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>6. Traction and Milestones</b>
                      </p>
                      <p>
                        Investors want to know that your idea works. Do you have
                        customers or users? How much growth have you achieved so
                        far? Share key metrics, partnerships, or any significant
                        milestones you’ve achieved. If you've got success
                        stories or customer feedback, showcase them!{" "}
                      </p>
                      <p>
                        Use online metrics such as website traffic, social media
                        engagement, and customer feedback to show traction. If
                        you’ve run successful digital campaigns, highlight them.{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>7. Know Your Competition</b>
                      </p>
                      <p>
                        Show investors that you’ve done your homework by
                        identifying your main competitors. What are their
                        strengths and weaknesses? More importantly, tell them
                        how different you are from others.
                      </p>
                      <p>
                        Position Your Brand: Use digital marketing to establish
                        your brand’s presence. A strong online presence, with
                        high search engine rankings and positive reviews, can be
                        a powerful tool.
                      </p>
                    </li>

                    <li>
                      <p>
                        <b>8. Financial Projections</b>
                      </p>
                      <p>
                        Here’s where you tell investors what you need. How much
                        funding are you seeking? How will you use the money? Be
                        specific. Investors want to know that their money will
                        be used wisely and will lead to growth.{" "}
                      </p>
                      <p>
                        Specify the amount of funding you’re seeking and clearly
                        outline how the funds will be used. Whether it’s to
                        build products, boost marketing, or expand operations,
                        investors need to see that their money will be spent
                        wisely. Discuss the expected return on investment (ROI)
                        and potential exit strategies.
                      </p>
                    </li>

                    <li>
                      <p>
                        <b>9. Funding Requirements</b>
                      </p>
                      <p>
                        Here’s where you tell investors what you need. How much
                        funding are you seeking? How will you use the money? Be
                        specific. Investors want to know that their money will
                        be used wisely and will lead to growth.{" "}
                      </p>
                      <p>
                        Specify the amount of funding you’re seeking and clearly
                        outline how the funds will be used. Whether it’s to
                        build products, boost marketing, or expand operations,
                        investors need to see that their money will be spent
                        wisely. Discuss the expected return on investment (ROI)
                        and potential exit strategies.
                      </p>
                    </li>

                    <li>
                      <p>
                        <b>10. Wrap It Up</b>
                      </p>
                      <p>
                        Summarize your main points and make a strong final
                        impression. Reiterate why your business is a great
                        investment opportunity. End with a call to action, like
                        scheduling a follow-up meeting or inviting questions.
                      </p>
                      <p>
                        Be ready for Q&A: Anticipate the questions investors
                        might ask and be prepared with clear, concise answers.
                        Show that you’re confident and knowledgeable about your
                        business.
                      </p>
                    </li>
                  </ul>
                </section>
                <section id="section2">
                  <h2>How Digital Marketing can help you?</h2>
                  <ul>
                    <li>
                      - Building credibility: A strong online presence builds
                      trust. Use content marketing, social media, and SEO to
                      position yourself as an expert in your field.
                    </li>
                    <li>
                      - Data-driven insights: Leverage digital marketing
                      analytics to understand your market and customers. This
                      data can make your pitch more compelling and backed by
                      facts.
                    </li>
                    <li>
                      - Engaging storytelling: Digital marketing is about
                      sharing stories that connect with people. Use visuals,
                      videos, and infographics to make your pitch more
                      interesting, and would easily fit into any investor pitch
                      deck template.
                    </li>
                    <li>
                      - Maximizing reach: Use digital channels to reach a wider
                      audience and attract potential investors. A well-executed
                      digital campaign can create buzz and interest in your
                      business.
                    </li>
                    <li>
                      <p>
                        <b>Make it big, make it right!</b>
                      </p>
                      <p>
                        Want to make your investor pitch the best one? At SAAA
                        Consultants, we create your digital presence that not
                        only grabs attention but also wins over investors.
                      </p>
                      <i>Schedule a free exploration call today!!</i>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="blog-sidebar">
                <h2>On this page</h2>
                <ul>
                  <li>
                    <a href="#section1">What is an Investor Pitch?</a>
                  </li>
                  <li>
                    <a href="#section2">How Digital Marketing can help you?</a>
                  </li>
                  {/* <li>
                     <a href="#section3">Types of Funding:</a>
                  </li> */}
                  {/* <li>
                     <a href="#section4">How to raise funds to start a business?</a>
                  </li> */}
                  {/* <li>
                     <a href="#section5"> How much does it cost to incorporate a Company?</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvestorPitch;
